import React,{useState,useEffect} from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';

const ModalNuevo = ({addCollegiate,handledMostrarModal,colegiadoSeleccionado,tipoEdicion}) => {
    const [fileAdjunto, setFileAdjunto] = useState({ file: '', nombreArchivo: '' })
    useEffect(()=>{
      const file = colegiadoSeleccionado.file ? colegiadoSeleccionado.file: { file: '', nombreArchivo: '' }
      setFileAdjunto(file)
    },[colegiadoSeleccionado])

    const initialValues = {
      "dni": colegiadoSeleccionado.dni,
      "birthday": colegiadoSeleccionado.birthday,
      "email": colegiadoSeleccionado.email,
      "clad": colegiadoSeleccionado.clad,
      "name": colegiadoSeleccionado.name,
      "lastName": colegiadoSeleccionado.lastName,
      "state": colegiadoSeleccionado.state,
      "date": colegiadoSeleccionado.date,
      "nombreArchivo":colegiadoSeleccionado.file? colegiadoSeleccionado.file.nombreArchivo: ''
    }
    console.log(initialValues)
    const handleCLick =  (values)=>{
        
        const datos =  {
          "dni": values.dni,
          "birthday": values.birthday,
          "email": values.email,
          "clad": values.clad,
          "date": values.date,
          "name": values.name,
          "lastName": values.lastName,
          "state":values.state,
          "file": fileAdjunto.file? fileAdjunto: ''
        }

         
        addCollegiate(datos);
    }

    const getBase64 = (file) => {
      return new Promise((resolve) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => {
          const baseURL = reader.result
          resolve(baseURL)
        }
      })
    }
  
      
  const handleInputChangeFile = (even) => {
    const file = even.target.files[0]
    getBase64(file)
      .then((result) =>
        setFileAdjunto({
          nombreArchivo: file.name,
          file: result
        })
      )
      .catch((err) => {
        console.log(err)
      })
  }
    
    const handlkeyPressNumbers=(evento)=>{
        const tecla = evento.which;
        const caracter = String.fromCharCode(tecla)
        const regex = /^[0-9]\d*$/i;
        if(!regex.test(caracter)){ 
            evento.preventDefault();
            return false;
        }
    }

    const titulo =()=>{
        if (tipoEdicion ===1) {
           return 'Agregar Colegiado'
        }
        else if (tipoEdicion ===2) {
          return 'Editar Colegiado'
        }
        else if (tipoEdicion ===3) {
          return 'Eliminar colegiado'
        }
    }

    const SignupSchema = Yup.object().shape({
          dni: Yup.string()
          .min(7, 'DNI inconpleto')
          .max(8, 'El DNI  solo contiene 8 caracteres')
          .required('El campo es requerido'),
          name: Yup.string().required('El campo es requerido'),
          lastName: Yup.string().required('El campo es requerido'),
          clad: Yup.number().typeError('Solo debe ingresar numeros.').required('El campo es requerido'),
          email: Yup.string().required('El campo es requerido'),
          birthday: Yup.string().required('El campo es requerido'),
          date: Yup.string().required('El campo es requerido'),
          nombreArchivo:Yup.string().notRequired()
      });

    return ( <>
   <div
        className="fixed z-10 inset-0 overflow-y-auto rounded-full"
        aria-labelledby="modal-title"
        role="dialog"
        aria-modal="true"
      >
      <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div
          className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
          aria-hidden="true"
        ></div>
        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
          &#8203;
        </span>
        <div className="inline-block align-bottom  rounded-3xl  bg-white text-left  shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-2xl sm:w-5/12">
          <Formik
            initialValues={initialValues}
            onSubmit={handleCLick}
            validationSchema={SignupSchema}
          >
            {({ errors, touched }) => (
              <Form  className=" ">
                <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4  rounded-full ">
                  <div className=" sm:items-start  ">
                    <div className="text-center pb-1">
                      <h3
                        className="text-lg leading-6 font-bold text-center  text-gray-900 h2-underline font-poppins"
                        id="modal-title"
                      >
                        {titulo()}
                        
                      </h3>
                    </div>
                    <br />
                    <div  className="grid grid-cols-2 gap-x">
                      <div className="mb-6 pr-5">
                              <label className=" block text-gray-600 font-normal text-xs font-poppins  " 
                                      htmlFor={'name'}>CLAD
                              </label>
                              <Field type="text" 
                                  className=" focus:ring-indigo-500 focus:border-indigo-500 px-2 py-2 bg-gray-50 rounded-xl w-full  border border-gray-300" 
                                  maxLength={8} 
                                  onKeyPress={(e)=>{handlkeyPressNumbers(e);}}
                                  name="clad"
                                  />
                                  {errors.clad && touched.clad ? (
                                      <div className="text-red-500 text-xs">{errors.clad}</div>
                                  ) : null}
                        </div>
                        <div className="mb-6 pr-5">
                            <label className=" block text-gray-600 font-normal text-xs font-poppins  " 
                                    htmlFor={'date'}>Afiliacion
                            </label>
                            <Field type="date" 
                                className=" focus:ring-indigo-500 focus:border-indigo-500  px-2 py-2 bg-gray-50 rounded-xl w-full border border-gray-300 " 
                                maxLength={10} 
                                name="date"
                                />
                                {errors.date && touched.date ? (
                                    <div className="text-red-500 text-xs">{errors.date}</div>
                                ) : null}
                      </div>
                    </div>
                    <div className="grid grid-cols-2 gap-x">
                      <div className="mb-6 pr-5">
                            <label className=" block text-gray-600 font-normal text-xs font-poppins  " 
                                    htmlFor={'name'}>Nombres
                            </label>
                            <Field type="text" 
                                className=" focus:ring-indigo-500 focus:border-indigo-500  px-2 py-2 bg-gray-50 rounded-xl w-full  border border-gray-300" 
                                maxLength={40} 
                                name="name"
                                />
                                {errors.name && touched.name ? (
                                    <div className="text-red-500 text-xs">{errors.name}</div>
                                ) : null}
                      </div>
                      <div className="mb-6 pr-5">
                            <label className=" block text-gray-600 font-normal text-xs font-poppins  " 
                                    htmlFor={'lastName'}>Apellidos
                            </label>
                            <Field type="text" 
                                className=" focus:ring-indigo-500 focus:border-indigo-500  px-2 py-2 bg-gray-50 rounded-xl w-full  border border-gray-300" 
                                maxLength={40} 
                                name="lastName"
                                />
                                {errors.lastName && touched.lastName ? (
                                    <div className="text-red-500 text-xs">{errors.lastName}</div>
                                ) : null}
                      </div>
                    </div>

                    <div className="grid grid-cols-2 gap-x">
                      <div className="mb-6 pr-5">
                            <label className=" block text-gray-600 font-normal text-xs font-poppins  " 
                                    htmlFor={'name'}>DNI
                            </label>
                            <Field type="text" 
                                className=" focus:ring-indigo-500 focus:border-indigo-500  px-2 py-2 bg-gray-50 rounded-xl w-full border border-gray-300 " 
                                maxLength={8} 
                                onKeyPress={(e)=>{handlkeyPressNumbers(e);}}
                                name="dni"
                                />
                                {errors.dni && touched.dni ? (
                                    <div className="text-red-500 text-xs">{errors.dni}</div>
                                ) : null}
                      </div>
                      <div className="mb-6 pr-5">
                            <label className=" block text-gray-600 font-normal text-xs font-poppins  " 
                                    htmlFor={'birthday'}>Cumpleaños
                            </label>
                            <Field type="date" 
                                className=" focus:ring-indigo-500 focus:border-indigo-500  px-2 py-2 bg-gray-50 rounded-xl w-full border border-gray-300 " 
                                maxLength={10} 
                                name="birthday"
                                />
                                {errors.birthday && touched.birthday ? (
                                    <div className="text-red-500 text-xs">{errors.birthday}</div>
                                ) : null}
                      </div>
                    </div>

                    <div className="grid grid-cols-2 gap-x">
                      <div className="mb-6 pr-5">
                            <label className=" block text-gray-600 font-normal text-xs font-poppins   " 
                                    htmlFor={'email'}>Correo
                            </label>
                            <Field type="text" 
                                className=" focus:ring-indigo-500 focus:border-indigo-500  px-2 py-2 bg-gray-50 rounded-xl w-full  border border-gray-300" 
                                maxLength={140} 
                                name="email"
                                />
                                {errors.email && touched.email ? (
                                    <div className="text-red-500 text-xs">{errors.email}</div>
                                ) : null}
                      </div>
                      <div className="mb-6 pr-5">
                        <div   className="flex items-start mb-6 pt-8">
                            <div className="flex items-center h-5">
                                <Field type="checkbox" 
                                    name={'state'}  
                                    aria-describedby="remember"   
                                    className="bg-gray-50 border-gray-300 focus:ring-3 focus:ring-blue-300 h-4 w-4 rounded" 
                                />
                            </div>
                            <div className="text-sm ml-3">
                                <label htmlFor={'state'} className="font-medium text-gray-900">{'Habilitado'}</label>
                            </div>

                        </div>
                      </div>
                    </div>
                    <div className='grid grid-cols-1 gap-1'>
                    <label  className=" ">
                        <div className="flex flex-wrap items-stretch w-full mb-4 relative">
                            <div className="flex -mr-px">
                                <span className="material-icons bg-gray-100 flex cursor-pointer items-center leading-normal bg-grey-lighter rounded rounded-r-none border border-r-0 border-grey-light px-3 whitespace-no-wrap text-grey-dark text-gray-400">
                                    attachment
                                    <Field  name={'file'}  >
                                        { (obj)=>{
                                                const {setFieldValue }= obj.form
                                                return <input type="file"   id={'file'}   name={'file'}  onChange={(event) => {setFieldValue('nombreArchivo',event.currentTarget.files[0].name) ;  setFieldValue('file',event.currentTarget.files[0]) ;  handleInputChangeFile(event); }}  
                                                className="h-full w-full   hidden opacity-0"/>
                                            }
                                        }
                                    </Field>
                                </span>
                            </div>	
                            <Field type="text"  name='nombreArchivo'   readOnly className="flex-shrink  rounded-md flex-grow flex-auto border-gray-300 leading-normal w-px flex-1 border h-10 border-grey-light rounded rounded-l-none shadow-sm px-3 relative focus:outline-none focus:ring-indigo-500 focus:border-indigo-500" placeholder="Documento de sustento"/>
                        </div>	
                    </label>
                    </div>
                  </div>
                </div>
                <div className=" bg-gray-50  px-4 py-3  rounded-bl-3xl rounded-br-3xl sm:px-6 sm:flex sm:flex-row-reverse">
                  <button
                    type="submit"
                    className="w-full inline-flex justify-center rounded-md border font-poppins uppercase border-transparent shadow-sm px-4 py-2 bg-green-400 text-base font-medium text-white  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                  >
                    {tipoEdicion === 3 ? 'Eliminar' : 'Guardar'}
                  </button>
                  <button
                    type="button"
                    onClick={handledMostrarModal}
                    className="mt-3 w-full inline-flex justify-center font-poppins rounded-md   uppercase   shadow-sm px-4 py-2 bg-white text-base font-medium text-red-500 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                  >
                    Cancelar
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
    </> );
}
 
export default ModalNuevo;