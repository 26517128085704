import React from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';

const ModalNuevo = ({addCollegiate,handledMostrarModal,colegiadoSeleccionado,tipoEdicion}) => {
 

    const initialValues = {
      "titulo": colegiadoSeleccionado.titulo,
      "Descripcion": colegiadoSeleccionado.Descripcion,
    }
    console.log(initialValues)
    const handleCLick =  (values)=>{
        
        const datos =  {
          "titulo": values.titulo,
          "Descripcion": values.Descripcion,
          
        }
         
        addCollegiate(datos);
    }
 
 
    const titulo =()=>{
        if (tipoEdicion ===1) {
           return 'Agregar Bolsa de trabajo'
        }
        else if (tipoEdicion ===2) {
          return 'Editar Bolsa de trabajo'
        }
        else if (tipoEdicion ===3) {
          return 'Eliminar Bolsa de trabajo'
        }
    }

    const SignupSchema = Yup.object().shape({
      titulo: Yup.string().required('El campo es requerido'),
          Descripcion: Yup.string().required('El campo es requerido'),
 
      });

    return ( <>
   <div
        className="fixed z-10 inset-0 overflow-y-auto rounded-full"
        aria-labelledby="modal-title"
        role="dialog"
        aria-modal="true"
      >
      <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div
          className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
          aria-hidden="true"
        ></div>
        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
          &#8203;
        </span>
        <div className="inline-block align-bottom  rounded-3xl  bg-white text-left  shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-2xl sm:w-5/12">
          <Formik
            initialValues={initialValues}
            onSubmit={handleCLick}
            validationSchema={SignupSchema}
          >
            {({ errors, touched }) => (
              <Form  className=" ">
                <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4  rounded-full ">
                  <div className=" sm:items-start  ">
                    <div className="text-center pb-1">
                      <h3
                        className="text-lg leading-6 font-bold text-center  text-gray-900 h2-underline font-poppins"
                        id="modal-title"
                      >
                        {titulo()}
                        
                      </h3>
                    </div>
                    <br />
                    <div className="grid grid-cols-2 gap-x">
                      <div className="mb-6 pr-5">
                            <label className=" block text-gray-600 font-normal text-xs font-poppins  " 
                                    htmlFor={'titulo'}>Titulo
                            </label>
                            <Field type="text" 
                                className=" focus:ring-indigo-500 focus:border-indigo-500  px-2 py-2 bg-gray-50 rounded-xl w-full  border border-gray-300" 
                                maxLength={40} 
                                name="titulo"
                                />
                                {errors.titulo && touched.titulo ? (
                                    <div className="text-red-500 text-xs">{errors.titulo}</div>
                                ) : null}
                      </div>
                      <div className="mb-6 pr-5">
                            <label className=" block text-gray-600 font-normal text-xs font-poppins  " 
                                    htmlFor={'name'}>Descripcion
                            </label>
                            <Field type="text" 
                                className=" focus:ring-indigo-500 focus:border-indigo-500  px-2 py-2 bg-gray-50 rounded-xl w-full  border border-gray-300" 
                                maxLength={40} 
                                name="Descripcion"
                                />
                                {errors.Descripcion && touched.Descripcion ? (
                                    <div className="text-red-500 text-xs">{errors.Descripcion}</div>
                                ) : null}
                      </div>
                    </div>
 
              
                  </div>
                </div>
                <div className=" bg-gray-50  px-4 py-3  rounded-bl-3xl rounded-br-3xl sm:px-6 sm:flex sm:flex-row-reverse">
                  <button
                    type="submit"
                    className="w-full inline-flex justify-center rounded-md border font-poppins uppercase border-transparent shadow-sm px-4 py-2 bg-green-400 text-base font-medium text-white  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                  >
                    {tipoEdicion === 3 ? 'Eliminar' : 'Guardar'}
                  </button>
                  <button
                    type="button"
                    onClick={handledMostrarModal}
                    className="mt-3 w-full inline-flex justify-center font-poppins rounded-md   uppercase   shadow-sm px-4 py-2 bg-white text-base font-medium text-red-500 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                  >
                    Cancelar
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
    </> );
}
 
export default ModalNuevo;