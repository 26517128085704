import * as firebase from 'firebase/app';
const firebaseConfig = {
    apiKey: `${process.env.REACT_APP_NOT_API_KEY_FIREBASE}`,
    authDomain:  `${process.env.REACT_APP_NOT_AUTH_DOMAIN_FIREBASE}`,
    projectId:  `${process.env.REACT_APP_NOT_PROJECT_ID_FIREBASE}`,
    storageBucket:  `${process.env.REACT_APP_NOT_STORAGE_BUCKET_FIREBASE}`,
    messagingSenderId:  `${process.env.REACT_APP_NOT_MESSAGING_SENDERID_FIREBASE}` ,
    appId:  `${process.env.REACT_APP_NOT_APP_ID_FIREBASE}`,
    measurementId:  `${process.env.REACT_APP_NOT_MEASUREMENT_ID_FIREBASE}`
};
firebase.initializeApp(
    firebaseConfig
);
