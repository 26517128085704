import React, { useState, useEffect } from 'react';
import { collection, addDoc, query, getDocs,setDoc, doc ,deleteDoc } from "firebase/firestore";
import { getFirestore } from "firebase/firestore";
import { ModalNuevo } from './components';

const TablaMantenimiento = () => {

    const [verModalNuevo,setVerModalNuevo] = useState(false);
    const [tipoEdicion,settipoEdicion] = useState(0);
    const [colegiados,setcolegiados] = useState([]);
    const [colegiadosBuscado,setcolegiadosBuscado] = useState([]);
    const [colegiadoSeleccionado,setcolegiadoSeleccionado] = useState({
        "titulo": '',
        "Descripcion": '',
      });


    const handledMostrarModal =()=>{
        const valor = verModalNuevo ? false : true; 
        settipoEdicion(1)
        setcolegiadoSeleccionado({
            "titulo": '',
            "Descripcion": '',
         })
        setVerModalNuevo(valor)
    }

    useEffect(()=>{
        const search = async()=>{
            const db = getFirestore();
            const q = query(collection(db, "bolsatrabajo"));
            const querySnapshot = await getDocs(q);
            const iniColegiados= []
            querySnapshot.forEach((doc) => {
                const data = doc.data();
                data.id = doc.id;
                iniColegiados.push(data)
            });
            setcolegiados(iniColegiados)
            setcolegiadosBuscado(iniColegiados)
        }

        search();
    },[])

    const refresh = async()=>{
        const db = getFirestore();
        const q = query(collection(db, "bolsatrabajo"));
        const querySnapshot = await getDocs(q);
        const iniColegiados= []
        querySnapshot.forEach((doc) => {
            const data = doc.data();
            data.id = doc.id;
            iniColegiados.push(data)
        });
        setcolegiados(iniColegiados)
        setcolegiadosBuscado(iniColegiados)
    }
    const filtrar =  (terminoBusqueda) => {

        if(terminoBusqueda.trim().length > 0){
            const resultadosBusqueda = colegiados.filter((elemento) => {
                let element ;
                if (
                  elemento.name?.toString().toLowerCase().includes(terminoBusqueda.toLowerCase()) ||
                  elemento.lastName?.toString().toLowerCase().includes(terminoBusqueda.toLowerCase())
                ) {
                    element = elemento
                }
                return element;
              })
              setcolegiadosBuscado(resultadosBusqueda);
        }else{
            setcolegiadosBuscado(colegiados);
        }
      }
  
    const add =async(item)=>{
        try {
            const db = getFirestore();
            if (tipoEdicion=== 1) {
                await addDoc(collection(db, "bolsatrabajo"), {
                    "titulo":  item.titulo,
                    "Descripcion":   item.Descripcion,
                });
                refresh();
                setVerModalNuevo(false)

            } else if (tipoEdicion=== 2) {

                await setDoc(doc(db, "bolsatrabajo",colegiadoSeleccionado.id), {
                    "titulo":  item.titulo,
                    "Descripcion":   item.Descripcion,
                });
                refresh();
                setVerModalNuevo(false)
            } else if (tipoEdicion=== 3) {
                await deleteDoc(doc(db, "bolsatrabajo",colegiadoSeleccionado.id));
                refresh();
                setVerModalNuevo(false)
            }
            console.log(item)
        } catch (e) {
            console.error("Error adding document: ", e,JSON.stringify(item));
        }
    }
    
    const editarColegiado = (item)=>{
        setcolegiadoSeleccionado(item)
        settipoEdicion(2)
        const valor = verModalNuevo ? false : true; 
        setVerModalNuevo(valor)
    }

    const eliminarColegiado = (item)=>{
        setcolegiadoSeleccionado(item)
        settipoEdicion(3)
        const valor = verModalNuevo ? false : true; 
        setVerModalNuevo(valor)
    }
 
     
    return (
        <>
            <div className='bg-neutral-100'>
                <div className='mx-24 mt-6  px-2 py-2'>
                    <h1 className='text-4xl mt-12 mb-6 font-bold font-poppins text-green-800'>Mantenimiento de Bolsa de trabajo</h1>

                    <div className=' relative'>
                        <input 
                            type={'text'} 
                            onChange={(e)=>filtrar(e.target.value)}
                            name="tiulo"
                            placeholder={'Buscar por titulo'} 
                            className='w-full border border-green-800 bg-gray-100 px-4 py-5 rounded-xl text-green-8000 text-lg font-light uppercase '
                        />
                        <button 
                            className='text-gray-100 w-32 text-sm absolute right-6 top-4 rounded-md bg-green-800 px-2 py-2'>
                            Buscar 
                        </button>
                    </div>
                    <div className="flex flex-col justify-center h-full">
                        <div className="w-full mx-auto bg-neutral-100 shadow-lg rounded-xl border border-green-800 mt-10 mb-10">
                            <header className="px-5 py-4 border-b border-green-800 relative">
                                <h2 className="font-semibold text-green-800">Bolsa de trabajo</h2>
                                <button 
                                    onClick={handledMostrarModal}
                                    className='text-gray-100 w-20 text-sm absolute right-3 top-3 rounded-md bg-green-800 px-2 py-2'>
                                    Agregar
                                </button>
                            </header>
                            <div className="p-3">
                                <div className="overflow-x-auto ">
                                    <table className="table-auto w-full ">
                                        <thead className="text-sm font-semibold uppercase text-green-800 ">
                                            <tr>
                                                <th className="p-2 whitespace-nowrap">
                                                    <div className="font-semibold text-left">Titulo</div>
                                                </th>
                                                <th className="p-2 whitespace-nowrap">
                                                    <div className="font-semibold text-left">Descripcion</div>
                                                </th>
                                                <th className="p-2 whitespace-nowrap">
                                                    <div className="font-semibold text-center"></div>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody className="text-xs divide-y divide-green-800">
                                        {
                                            colegiadosBuscado.length > 0 ?  colegiadosBuscado.map((item,index) =>(
                                                    <tr key={index}>
                                                    <td className="p-2 whitespace-nowrap">
                                                        <div className="font-medium text-green-800">{item.titulo}</div>
                                                    </td>
                                                    <td className="p-2 whitespace-nowrap">
                                                        <div className="text-left text-green-800 capitalize">{item.Descripcion }   </div>
                                                    </td>
                                                    <td>
                                                        <div className='flex'>
                                                            
                                                            <div className='flex-initial w-4 mr-2'>
                                                                <span 
                                                                    onClick={()=>editarColegiado(item)}
                                                                    className="material-icons text-blue-500 text-xs cursor-pointer">
                                                                    edit
                                                                </span>
                                                            </div>
                                                            <div className='flex-initial w-4'>
                                                                <span 
                                                                     onClick={()=>eliminarColegiado(item)}
                                                                className="material-icons text-red-500 text-xs cursor-pointer">
                                                                delete
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    </tr>
                                                )):
                                                <tr>
                                                    <td className='text-center' colSpan={4}>
                                                        <h2 className='font-extralight mt-4 mb-4  text-center text-green-8000 text-xl'> No se encontraron registros</h2>
                                                    </td>
                                                </tr>
                                        }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {
                verModalNuevo ? <ModalNuevo tipoEdicion={tipoEdicion} addCollegiate={add}  handledMostrarModal={handledMostrarModal}  colegiadoSeleccionado={colegiadoSeleccionado}  /> : ""
            }
        </> 
    );
}
 
export default TablaMantenimiento;