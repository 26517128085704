import React, { useState,useEffect } from 'react';
import colegiadosbusqueda from '../../img/colegiados-busqueda.svg'
import Table from '../../components/Table'
import { getFirestore, collection, getDocs } from "firebase/firestore"
const Buscar = () => {
    const [colegiados, setcolegiados] = useState([])
    const [colegiadosBuscados, setcolegiadosBuscados] = useState([])

    useEffect(()=>{
        const onLoadView = async ()=>{
        
            const db = getFirestore();
            const queryColegiados= await getDocs(collection(db, "collegiate"));
            setcolegiados([])
            const iniColegiados= []
            queryColegiados.forEach((doc) => {
                const data = doc.data();
                data.id = doc.id;
                iniColegiados.push(data)
            });
            setcolegiados(iniColegiados)
        }
        onLoadView();
    },[])


    const filtrar = (terminoBusqueda) => {

        if(terminoBusqueda.trim().length > 0){
            const resultadosBusqueda = colegiados.filter((elemento) => {
                let element ;
                if (
                  elemento.name?.toString().toLowerCase().includes(terminoBusqueda.toLowerCase()) ||
                  elemento.lastName?.toString().toLowerCase().includes(terminoBusqueda.toLowerCase())
                ) {
                    element = elemento
                }
                return element;
              })
              setcolegiadosBuscados(resultadosBusqueda);
        }else{
            setcolegiadosBuscados([]);
        }
      }
    return (
    <>
        <div className='bg-neutral-100'>
            <div className='mx-24  px-2 py-2'>
                <h1 className='text-4xl mb-6 mt-12 font-bold font-poppins text-green-800'>Busqueda de Colegiados </h1>
                <p className='text-green-800   w-3/4'>
                Aquí usted encontrará información de nuestros miembros de la orden.
                Para consultar ingresar CLAD, nombres o apellidos del colegiado.
                </p>
            </div>
            <div className='mx-24  px-2 py-2 relative'>
                <input 
                    type={'text'} 
                    placeholder={'Buscar por nombres y apellidos'} 
                    className='w-full border border-green-800 bg-gray-100 px-4 py-5 rounded-xl text-green-800 text-lg font-light uppercase '
                    onChange={(e)=>filtrar(e.target.value)}
                />
                <button 
                    className='text-gray-100 w-32 text-sm absolute right-6 top-6 rounded-md bg-green-800 px-2 py-2'>
                    Buscar colegiado
                </button>
            </div>
            {
                 colegiadosBuscados.length > 0 ?  
                 <div  className='mx-24   px-2 py-2 ' >
                    <Table colegiados={colegiadosBuscados} />
                 </div>
                :
                <div  className='mx-56 mt-16  px-2 py-2 relative'>
                    <img className='' src={colegiadosbusqueda} alt="Busqeuda" />
                </div>
            }
          
        </div>
    </> 
    );
}
 
export default Buscar;