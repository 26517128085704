import {  BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import Inicio from '../wiews/Inicio/index';
import Organizacion from '../wiews/Organizacion/';
import Historia from '../wiews/Historia';
import Colegiaturas from '../wiews/Colegiaturas';
import Cuotas from '../wiews/Cuotas';
import Auth from '../wiews/Auth';
import BolsaTrabajo from '../wiews/BolsaTrabajo';
import Buscar from '../wiews/Buscar';
import Cumpleaños from '../wiews/Cumpleaños';
import Contacto from '../wiews/Contacto';
import Normativos from '../wiews/Normativos';
import Header from '../components/Header';
import Navbar from '../components/Navbars';
import Footer from '../components/Footer';
import '../config/firebaseConfig';


const App = () => {
  return (
    <>
      <Header>
        <Router>
        <Navbar/>
          <Routes> 
            <Route path="/" element={<Inicio/>}/>
            <Route path="/organizacion" element={<Organizacion/>}/>
            <Route path="/historia" element={<Historia/>}/>
            <Route path="/colegiaturas" element={<Colegiaturas/>}/>
            <Route path="/normativos" element={<Normativos/>}/>
            <Route path="/pago-cuotas" element={<Cuotas/>}/>
            <Route path="/buscar-colegiados" element={<Buscar/>}/>
            <Route path="/contacto" element={<Contacto/>}/>
            <Route path="/admin-web" element={<Auth/>}/>
            <Route path="/cumpleanos" element={<Cumpleaños/>}/>
            <Route path="/bolsa-trabajo" element={<BolsaTrabajo/>}/>
            
          </Routes>
        </Router>
      <Footer />

      </Header>
    </>
  )
}

export default App;
