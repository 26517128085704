import corladpasco from '../img/corladpasco.jpg'
import bolsatrabajo from '../img/bolsatrabajo.jpg'
import entregadediplomas from '../img/entregadediplomas.jpg'
import usuarionn from '../img/usuarionn.jpg'
import matos from '../img/F02641664195.jpg'
import direcregionalfoto from '../img/direcregionalfoto.jpeg';
import jimmymaximofoto from '../img/jimmymaximofoto.jpg';
import ELIORIVASALVARADO from '../img/ELIORIVASALVARADO.jpg';
import oliviahurtado from '../img/oliviahurtado.jpg';
import ybj from '../img/ybj.jpg';


const IMAGES = [
    {
      imageUrl: corladpasco,
      placeHolder: "Corlad pasco"
    },

    {
      imageUrl: entregadediplomas,
      placeHolder: "Diplomas"
    },
    {
      imageUrl: bolsatrabajo,
      placeHolder: "Bolsa de trabajo"
    },
  ];


  const consejoDirectivo = [
    {
      nombre:'HURTADO ANGULO, Olivia Beatriz',
      foto:oliviahurtado,
      cargo:'DECANA RGIONAL'
    },
    {
      nombre:'VEGA ESTACIO, Yolanda Elena',
      foto: usuarionn,
      cargo:'VICE-DECANA REGIONAL'
    },
    {
      nombre:'RIVAS ALVARADO, Elio',
      foto: ELIORIVASALVARADO,
      cargo:'DIRECTOR REGIONAL DE DESARROLLO Y HABILITACION PROFESIONAL'
    },
    {
      nombre:'BERNARDO JUSTINIANO, Yuly',
      foto:ybj,
      cargo:'DIRECTORA REGIONAL DE INFORMACION CIENTIFICA Y TECNOLOGICA'
    },
    {
      nombre:'MAYUNTUPA ECHEVARRIA, Roy Walter',
      foto:direcregionalfoto,
      cargo:'DIRECTOR REGIONAL DE SEGURIDAD Y BIENESTAR SOCIAL'
    },
    {
      nombre:'RIVAS CORNELIO, Yasari Saime',
      foto:usuarionn,
      cargo:'DIRECTORA REGIONAL SECRETARIO'
    },
    {
      nombre:'MATOS JARAMILLO, Veronica',
      foto:matos,
      cargo:'DIRECTORA REGIONAL DE ECONOMIA Y FINANZAS'
    },
    {
      nombre:'LOPEZ PEÑALOZA, Jimmy Maximo',
      foto:jimmymaximofoto,
      cargo:'DIRECTOR REGIONAL DE IMAGEN INSTITUCIONAL'
    }
  ]


  const documentosGenerales = [
    {
      nombre:'Ley de Creación del Colegio de Licenciados en Administración',
      link:''
    },
    {
      nombre:'Estatutos del Colegio de Licenciados en Administración',
      link:'https://corladarequipa.pe/recursos/documentos-normativos/1.%20NORMAS%20GENERALES/2.%20Decreto%20Supremo%20N%C2%B0%20020-2006-ED%20Estatutos%20del%20Colegio%20de%20Licenciados%20en%20Administraci%C3%B3n.pdf'
    },
    {
      nombre:'Ley del Ejercicio Profesional del Licenciado en Administración',
      link:''
    },
    {
      nombre:'Código de Ética del Colegio de Licenciados en Administración',
      link:''
    },
    {
      nombre:'Reglamento Interno del CLAD',
      link:''
    }
  ]
export  {
  IMAGES,
  consejoDirectivo,
  documentosGenerales
};