import Carousel from '../../components/Carousel'
import Cards from '../../components/Cards'
import {IMAGES} from '../../mock';

const Inicio = ()=>{
    return(
        <>
            <div className=" ">
               {/* <img src={corladpasco} alt="Imagen de inicio" /> */}
               <Carousel time={6000}>
                    { 
                        IMAGES.map((image, index) => <img key={index} src={image.imageUrl} alt={image.placeHolder}  width="2048" height={600} />) 
                    }
                </Carousel>
                <Cards/>
            </div>
        </>
    )
}

export default Inicio