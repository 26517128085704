import React from 'react';

const DotIcon = ({ selected }) => {
  const activeColor = "rgb(14 82 40)";
  const inactiveColor = "rgb(150 234 182)";
  const fillColor = selected ? activeColor : inactiveColor;

  return (
    <svg className="px-1 mr-6 animate-pulse opacity-40" width="12px" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="circle" role="button" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
        <path fill={fillColor} d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8z" />
    </svg>
  );
}

export default DotIcon;