import React from 'react';
import historia1  from '../../img/historia-corlad-1.png'
import historia2  from '../../img/historia-corlad-2.png'
const Historia = () => {
    return ( <>
        <div className='bg-neutral-100 px-2 py-2'>
            
            <div className='mx-44 mt-12 '>
                <h1 className='text-4xl mb-6 font-bold font-poppins text-green-800 '> Reseña Histórica </h1>
                <p className='text-green-800 w-3/4'>
                Los estudios a nivel de facultad en el Perú se inician en el año de 1960, en la que fue filial de la Universidad Comunal del Centro, hoy Universidad Nacional Federico Villarreal, cuyo Rector era el sabio peruano Javier Pulgar Vidal, y el primer Decano de Facultad, el Ing. Luis Heysen Incháustegui.
                </p>
                <div className='grid grid-cols-2 gap-2 mt-12'>
                    <div>
                        <img src={historia1}  className='border-2 border-green-800 ' alt="" />
                    </div>
                    <div>
                        <p className='text-green-800 ml-4'>
                        Dos años después, se crea la Escuela de Administración en la Facultad de Ciencias Económicas y Comerciales de la Universidad Nacional Mayor de San Marcos, siendo su Decano el Dr. Emilio Romero; a partir de entonces continuó la creación de Facultades de Administración en las distintas Universidades del país; tan es así que, según datos estadísticos proporcionados por la Asamblea Nacional de Rectores, nuestra profesión es la que más se enseña en las diversas Universidades Nacionales y Particulares del país, seguida por la profesión de ciencias económicas y contables.
                        </p>
                    </div>
                </div>

                <div className='grid grid-cols-2 gap-2 mt-12'>
                    <div>
                        <p className='text-green-800  ml-4'>
                        Desde un inicio, los administradores sentíamos la necesidad de contar con un Colegio Profesional, que tuviera como objetivo principal tener una institución que asuma aspectos deontológicos y del ejercicio profesional.
                        </p>
                        <br />
                        <p className='text-green-800  ml-4'>
                        Es por eso que, en el año 1964, los entonces senadores Luis Heysen Incháustegui, Carlos Manuel Cox y Ramiro Prialé Prialé presentaron ante la honorable ex Cámara de Senadores un proyecto de ley de colegiación de los licenciados en administración, el mismo que fue estudiado y aprobado con algunas modificaciones, por la respectiva comisión y por el pleno de esta cámara.
                        </p>
                    </div>
                    <div>

                    <img src={historia2}  className='border-2 border-green-800 ' alt="" />

                    </div>
                </div>
                <div className='mt-12'>
                    <p className='text-green-800  ml-4'>
                    En 1965, el proyecto paso a la honorable ex Cámara de Diputados, siendo derivado a las respectiva comisión de estudio; los estudiantes de las diferentes universidades, bajo la iniciativa del entonces estudiante José Ahumada Vásquez, Secretario General del Centro Federado de la Facultad de Ciencias Administrativas y Sociales de la Universidad Nacional Federico Villarreal, realizaron en el mes de diciembre de dicho año, el Primer Congreso Nacional de Estudiantes de Ciencias Administrativas, evento que contó con la presencia del entonces Presidente de la Cámara de Diputados, el Dr. Enrique Rivero Vélez.
                    </p>
                    <br />
                    <p  className='text-green-800  ml-4'>
                    Cuando ya había sido aprobado por la respectiva comisión y estaba en agenda para la aprobación del pleno de la Cámara de Diputados, se interrumpió la democracia en nuestro país, de manera que se tuvo que reiniciar con el nuevo gobierno los tramites de aprobación, existiendo por entonces la asociación peruana de administradores profesionales universitarios liberales (APAPUL), siendo su primer presidente el Lic. Adm. Teófilo Lagos Rodríguez (Q.E.P.D.).
                    </p>
                    <br />
                    <p  className='text-green-800  ml-4'>
                    En el año 1973, APAPUL, durante la gestión del Lic. Adm. Antonio Paucar Carbajal, realiza el Primer Congreso Nacional, ya de profesionales licenciados en administración, a fin de exigir al gobierno de turno la dación de la Ley de Colegiación.
                    </p>
                    <br />

                    <p  className='text-green-800  ml-4'>
                    Al Lic. Adm. Antonio Paucar le sucedió el Lic. Adm. Edmundo Fleming Campos; sin embargo es bajo la presidencia de APAPUL (la Asociación Peruana de Administradores Profesionales Universitarios Liberales), y en la gestión del Lic. Adm. José Ahumada Vásquez, Primer Decano Nacional y nuevamente Decano Nacional en el periodo 2006/2008, que se consiguió, después de 16 largos años de gestiones, la dación del Decreto Ley no.22087 del 14 de febrero de 1978 de creación de nuestra orden profesional.
                    </p>
                    <br />
                    <p className='text-green-800  ml-4'>
                    Es en la segunda gestión del Lic. Adm. José Ahumada que también se logra la dación del Decreto Supremo no. 020-2006-ed, que aprueba el nuevo estatuto de la orden, el mismo que debe de ser de estricto cumplimiento de los colegios
                    </p>
                    <br />
                    <br />
                    <br />
                </div>
            </div>
        </div>
    </> );
}
 
export default Historia;