
const Header = ({children})=>{
    return(
        <>
            <header className="h-8 bg-white">
                <div className="flex">
                    <p className="flex-none w-6">
                        <a href="https://www.google.com/maps/place/10%C2%B039'51.5%22S+76%C2%B015'17.4%22W/@-10.6645454,-76.2557817,18.17z/data=!4m5!3m4!1s0x0:0x3613797b81825975!8m2!3d-10.6642969!4d-76.2548305?hl=es"
                            target={'_blank'} rel="noreferrer"
                        >
                        <span className="material-icons text-green-800 text-lg align-middle">
                            place
                        </span>
                        </a>
                        
                    </p>
                    <p className="flex-initial  w-1/3 ">
                        <span className="text-gray-500 text-xs font-poppins">JR: HILARIO CABRERA 3052 - A1 SAN JUAN - YANACANCHA - PASCO </span>
                    </p>
                    <p className="flex-none w-8">
                        <span className="material-icons text-green-800 text-lg   align-middle">
                        local_phone
                        </span>
                    </p>
                    <p className="flex-none w-48">
                        <span className="text-gray-500 text-xs font-poppins">(063)792513 - 952205660</span>
                    </p>
                    <p className="flex-none w-8">
                        <span className="material-icons text-green-800 text-lg  align-middle">
                        event
                        </span>
                    </p>
                    <p className="flex-none w-38">
                        <span className="text-gray-500 text-xs font-poppins">Horario de Atención: 09:00AM - 12:00PM y 03:00PM - 06:00PM  </span>
                    </p>
                    <p className="flex-none"> 

                    </p>
                </div>
            </header>
            <div>
                {children}
            </div>
        </>
    )
}

export default Header