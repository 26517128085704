import React from 'react';


const TablaCumpleanos = ({colegiados}) => {

 

    return ( <>
    <section className="">
    <div className="flex flex-col justify-center h-full">
        <div className="w-full mx-auto bg-neutral-100 shadow-lg rounded-xl border border-green-800 mt-10 mb-10">
            <header className="px-5 py-4 border-b border-green-800">
                <h2 className="font-semibold text-green-800">Colegiados</h2>
            </header>
            <div className="p-3">
                <div className=" ">
                    <table className="table-auto w-full ">
                        <thead className="text-sm font-semibold uppercase text-green-800 ">
                            <tr>
                                <th className="p-2 whitespace-nowrap">
                                    <div className="font-semibold text-left">CLAD</div>
                                </th>
                                <th className="p-2 whitespace-nowrap">
                                    <div className="font-semibold text-left">Nombres y Apellidos</div>
                                </th>
                                <th className="p-2 whitespace-nowrap">
                                    <div className="font-semibold text-left">DNI</div>
                                </th>
                                <th className="p-2 whitespace-nowrap">
                                    <div className="font-semibold text-center">Fecha</div>
                                </th>
                            </tr>
                        </thead>
                        <tbody className="text-xs divide-solid divide-x divide-y divide-green-800">
                           {
                            colegiados.map((item,index) =>(
                                <tr key={index}>
                                <td className="p-2 whitespace-nowrap border">
                                    <div className="font-medium text-green-800">{item.clad}</div>
                                </td>
                                <td className="p-2 whitespace-nowrap border">
                                    <div className="text-left text-green-800 capitalize">{item.name?.toLowerCase()} {item.lastName?.toLowerCase()} </div>
                                </td>
                                <td className="p-2 whitespace-nowrap border">
                                    <div className="text-left font-medium text-green-800">{item.dni}</div>
                                </td>
                                <td className="p-2 whitespace-nowrap border">
                                    <div className="text-center font-medium text-green-800">{item.birthday}</div>
                                </td>
                             </tr>
                            ))
               
                           }
                          
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</section>
    
    </> );
}
 
export default TablaCumpleanos;