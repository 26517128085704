import React from 'react';
import { consejoDirectivo } from '../../mock'
const Organizacion = () => {
    return ( <>
        <div className='bg-neutral-100 px-2 py-2 '>
            <div className='mx-24 mt-6'>
                <h1 className='text-4xl mb-6 font-bold font-poppins text-green-800'>Nuestra Organización</h1>
                <p className='text-green-800   w-3/4'>
                Nuestra organización esta conformada por personas profesionales dedicadas a ejercer prioritariamente la profesión de administradores al más alto rango en las diversas entidades tanto públicas como privadas. Personas integrales cuyo objetivo primordial es el de dar a conocer y posicionar el CORLAD como el principal colegio profesional en la zona sur del país.
                </p>
            </div>
            <br />
            <div className='mx-24'>
                <h2 className='text-xl mb-6 font-bold font-poppins text-green-800'>Consejo Directivo Regional  </h2>
            </div>
            <div className='grid grid-cols-4 mx-28'>
                {
                    consejoDirectivo.map((x,i)=>
                    ( 
                        <div key={i} className='mt-8 h-38'>
                            <div className='text-center grid grid-cols-1 align-middle'>
                                <img src={x.foto} alt="Directivo" className='border-white border-4 w-48 hover:border-green-800 ease-in-out duration-300  h-48 ml-6 rounded-full' />

                            </div>
                            <p className='text-green-800 text-center font-bold capitalize mt-6 h-6 '>
                                {x.nombre.toLowerCase()}
                            </p>
                            <p className='text-gray-400 text-xs text-center font-poppins  capitalize mt-6 '>
                                {x.cargo.toLowerCase()}
                            </p>
                        </div>
                   )
                    )
                }
            </div>
        <br />
        <br />

        </div>
    </> );
}
 
export default Organizacion;