import React from "react";
import { Link } from "react-router-dom";


const Cards = ()=>{


    return(<>
    <div className="grid  grid-cols-4 gap-4 bg-neutral-100 pt-4 pb-10 " >
        <Link to={'/admin-web'}  className="cursor-pointer mb-8">
            <div className="text-center mb-8 mt-8">
                <span className="material-icons text-9xl text-center text-green-800 ">
                currency_exchange
                </span>
            </div>
            <h2 
                className="text-green-800  font-bold font-poppins text-center">
            Pago de cuotas</h2>
            <div className="text-center text-gray-500 text-sm px-5">
            Conozca los métodos de pago para ser miembro de Corlad Pasco

            </div>
        </Link>
        <Link  to={'/colegiaturas'} className=" cursor-pointer mb-8">
            <div className="text-center mb-8 mt-8">
                <span className="material-icons text-9xl text-center text-green-800">
                school
                </span>
            </div>
            <h2
            className="text-green-800  font-bold font-poppins text-center"
            >Colegiatura  </h2>
            <div className="text-center text-gray-500 text-sm px-5">
                Conozca los requisitos para ser miembro del CORLAD Pasco
            </div>
        </Link>
        <Link to={'/buscar-colegiados'}  className="mb-8 cursor-pointer">
            <div className="text-center mb-8 mt-8">
                <span className="material-icons text-9xl text-center text-green-800">
                person_search
                </span>
            </div>
            <h2
            className="text-green-800  font-bold font-poppins text-center"
            >Busqueda de colegiados
            </h2>
            <div
                className="text-center text-gray-500 text-sm px-5"
            >
            Busca a los profesionales que son miembros de nuestro colegio.
            </div>

        </Link>
        <Link  to={'/cumpleanos'}  className=" cursor-pointer mb-8">
            <  div className="text-center mb-8 mt-8">
                <span className="material-icons text-9xl text-center text-green-800">
                cake
                </span>
            </div>
            <h2
            className="text-green-800  font-bold font-poppins text-center"
            >Cumpleaños del mes</h2>
            <div
            className="text-center text-gray-500 text-sm px-5"
            >
            Celebramos con nuestros colegiados su cumpleaños.
            </div>
        </Link>
    </div>
    </>)
}

export default Cards