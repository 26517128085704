import React, { useState } from 'react';
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import TablaMantenimiento from '../../components/TablaMantenimiento'
import TablaBolsaTrabajo from '../../components/TablaBolsaTrabajo'
const Auth = () => {
    const [email,setEmail] = useState();
    const [password,setpassword] = useState();
    const [ingreso,setingreso] = useState(false);
    const [tipo,settipo] = useState(1);

    const handledClikcIniciarSesion = (event)=>{
        event.preventDefault();
        const auth = getAuth();
        signInWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
            // Signed in
             const user = userCredential.user;
             console.log(user,userCredential)
            setingreso(true,ingreso)
            // ...
        })
        .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            console.log(errorCode,errorMessage)
        });
    }

    const devolverComponente = (_tipo)=>{
        if (_tipo === 1) {
                return   <TablaMantenimiento/>
        }else {
            return   <TablaBolsaTrabajo/>
        }
    }

    const mostrarLista =()=>{
        if (tipo === 1) {
            settipo(2)
        }else {
            settipo(1)
        }
    }

    return ( 
        <>
            {
                ingreso ? 
                <div className='bg-neutral-100 '>
                    <button
                        className='w-28 mt-4 mr-4 bg-green-800 text-white border rounded-lg '
                        onClick={mostrarLista}
                    >
                        { tipo === 1 ? 'Ver Bolsa' : 'Ver Colegiados'}
                    </button>
            </div> :''
            }
            {
                ingreso ? devolverComponente(tipo) :
                <div className='bg-neutral-100 '>
                <div className="w-full min-h-screen bg-neutral-100  flex flex-col sm:justify-center items-center pt-6 sm:pt-0">
                    <div className="w-full sm:max-w-md p-5 mx-auto">
                        <h2 className="mb-12 text-center text-5xl font-extrabold text-green-800">Bienvenido.</h2>
                            <div>
                                <div className="mb-4">
                                    <label className="block mb-1 text-green-800" htmlFor="email">Correo electronico</label>
                                    <input id="email" 
                                        type="text" 
                                        name="email" 
                                        onChange={(e)=>setEmail(e.target.value)}
                                        className="py-2 px-3 border border-gray-300 focus:border-green-300 focus:outline-none focus:ring focus:ring-green-200 focus:ring-opacity-50 rounded-md shadow-sm disabled:bg-gray-100 mt-1 block w-full" 
                                    />
                                </div>
                                <div className="mb-4">
                                    <label className="block mb-1 text-green-800" htmlFor="password">Contraseña</label>
                                    <input
                                        onChange={(e)=>setpassword(e.target.value)}
                                        id="password" 
                                        type="password" 
                                        name="password" 
                                        className="py-2 px-3 border border-gray-300 focus:border-green-300 focus:outline-none focus:ring focus:ring-green-200 focus:ring-opacity-50 rounded-md shadow-sm disabled:bg-gray-100 mt-1 block w-full" />
                                </div>
                                <div className="mt-6 flex items-center justify-between">
                                    <div className="flex items-center">
                                    <input id="remember_me" type="checkbox" className="border border-gray-300 text-red-600 shadow-sm focus:border-green-300 focus:ring focus:ring-green-200 focus:ring-opacity-50" />
                                    <label htmlFor="remember_me" className="ml-2 block text-sm leading-5 text-gray-900"> Remember me </label>
                                    </div>
                                </div>
                                <div className="mt-6">
                                    <button 
                                        onClick={(e)=>handledClikcIniciarSesion(e)}
                                        className="w-full inline-flex items-center justify-center px-4 py-2 bg-green-800 border border-transparent rounded-md font-semibold capitalize text-white hover:bg-green-700 active:bg-green-700 focus:outline-none focus:border-green-700 focus:ring focus:ring-red-200 disabled:opacity-25 transition">
                                        Ingresar
                                    </button>
                                </div>
                                <div className="mt-6 text-center">
                                </div>
                            </div>
                    </div>
                </div>
            </div>
            }
        </>
    );
}
 
export default Auth;