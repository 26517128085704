import React from 'react';

const Contacto = () => {
    return ( <>
        <div className='bg-neutral-100 h-60'>
            <div className='mx-24 mt-6'>
                <h1 className='text-4xl mb-6 font-bold font-poppins text-green-800'> Contacto </h1>
            </div>
        </div>
    </> );
}
 
export default Contacto;
