import React, { useState } from 'react';
import TableBolsaLista from '../../components/TableBolsaLista'; 
import { getFirestore, collection, getDocs } from "firebase/firestore"
import colegiadosbusqueda from '../../img/colegiados-busqueda.svg'

const Cumpleaños = () => {
    const [colegiados, setcolegiados] = useState([])

    const onLoadView = async ()=>{
        
        const db = getFirestore();
        const queryColegiados= await getDocs(collection(db, "bolsatrabajo"));
        setcolegiados([])
        const iniColegiados= []
        queryColegiados.forEach((doc) => {
            const data = doc.data();
            data.id = doc.id;
            iniColegiados.push(data)
        });
        setcolegiados(iniColegiados)
    }

    onLoadView();

    return ( <>
        <div className='bg-neutral-100 px-2 py-2 '>
            <div className='mx-24 text-center mt-12'>
                <h1 className='text-4xl mb-6 font-bold font-poppins text-green-800'> Bolsa de trabajo</h1>
                
            </div>
            {
                 colegiados.length > 0 ?  
                 <div  className='mx-24   px-2 py-2 ' >
                    <TableBolsaLista colegiados={colegiados} />
                 </div>
                :
                <div  className='mx-56 mt-16  px-2 py-2 relative'>
                    <img className='' src={colegiadosbusqueda} alt="Busqeuda" />
                </div>
            }
        </div>
    
          
    </> );
}
 
export default Cumpleaños;