import React, { useState } from 'react';
import TablaCumpleanos from '../../components/TablaCumpleanos'; 
import { getFirestore, collection, getDocs } from "firebase/firestore"
import colegiadosbusqueda from '../../img/colegiados-busqueda.svg'
import moment from 'moment';

const Cumpleaños = () => {
    const [colegiados, setcolegiados] = useState([])

    const onLoadView = async ()=>{
        const mes = moment().format('MM')
        const db = getFirestore();
        const queryColegiados= await getDocs(collection(db, "collegiate"));
        setcolegiados([])
        const iniColegiados= []
        queryColegiados.forEach((doc) => {
            const data = doc.data();
            data.id = doc.id;
            iniColegiados.push(data)
        });
        const filter = iniColegiados.filter((item)=>(item.birthday.includes(`-${mes}-`) || item.birthday.includes(`/${mes}/`) ))
        setcolegiados(filter)
    }

    onLoadView();

    return ( <>
        <div className='bg-neutral-100 px-2 py-2 '>
            <div className='mx-24 text-center mt-12'>
                <h1 className='text-4xl mb-6 font-bold font-poppins text-green-800'> Cumpleaños del Mes </h1>
                <p className='mt-6  text-green-800'>
                El decano y la junta directiva felicitan a todos los miembros de la orden que cumplen años este mes
                </p>
            </div>
            {
                 colegiados.length > 0 ?  
                 <div  className='mx-24   px-2 py-2 ' >
                    <TablaCumpleanos colegiados={colegiados} />
                 </div>
                :
                <div  className='mx-56 mt-16  px-2 py-2 relative'>
                    <img className='' src={colegiadosbusqueda} alt="Busqeuda" />
                </div>
            }
        </div>
    
          
    </> );
}
 
export default Cumpleaños;