import React from 'react';


const TableBolsaLista = ({colegiados}) => {

    

    return ( <>
    <section className="">
    <div className="flex flex-col justify-center h-full">
        <div className="w-full mx-auto bg-neutral-100 shadow-lg rounded-xl border border-green-800 mt-10 mb-10">
            <header className="px-5 py-4 border-b border-green-800">
                <h2 className="font-semibold text-green-800">Bolsa de trabajo</h2>
            </header>
            <div className="p-3">
                <div className="overflow-x-auto ">
                    <table className="table-auto w-full ">
                        <thead className="text-sm font-semibold uppercase text-green-800 ">
                            <tr>
                                <th className="p-2 whitespace-nowrap">
                                    <div className="font-semibold text-left">Titulo</div>
                                </th>
                                <th className="p-2 whitespace-nowrap">
                                    <div className="font-semibold text-left">Descripcion </div>
                                </th>
                            </tr>
                        </thead>
                        <tbody className="text-xs divide-y divide-green-800">
                           {
                            colegiados.map((item,index) =>(
                                <tr key={index}>
                                    <td className="p-2 whitespace-nowrap">
                                        <div className="font-medium text-green-800">{item.titulo}</div>
                                    </td>
                                    <td className="p-2 whitespace-nowrap">
                                        <div className="text-left text-green-800 capitalize">{item.Descripcion }  </div>
                                    </td>
                                 </tr>
                            ))
               
                           }
                          
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</section>
    
    </> );
}
 
export default TableBolsaLista;