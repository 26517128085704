import React from 'react';


const Cuotas = () => {
    return (
    <>
        <div className='bg-neutral-100'>
            <div className='mx-24 mt-6'>
                <h1 className='text-4xl mb-6 font-bold font-poppins text-green-800'>Sistema de cuotas</h1>
            </div>
        </div>
    </> 
    );
}
 
export default Cuotas;