import  React, { useState } from "react";
import logo from '../../img/mini-logo-corlad.png';
import { Link } from "react-router-dom";
const Navbar = () => {
   
  const [institucional,setinstitucional] = useState(false);
  const [colegiados,setColegiados] = useState(false);
  const [servicios,setServicios] = useState(false);
  const [novedades,setNovedades] = useState(false);

  return (
    <>
      <div className="flex bg-green-800 h-16 relative">
        <div className="flex-initial w-20">
            <Link to={'/'}>
              <img src={logo} alt="Logo" className="bg-green-800  w-10 rounded mt-2 ml-4 " /> 
            </Link>
        </div>
        <div className="flex-initial w-1/3">
           <Link to={'/'}>
              <p className="font-poppins font-bold text-white mt-5">CORLAD PASCO</p>
           </Link>
        </div>
        <div className="absolute right-2">
            <div className="flex">
              <div onMouseLeave={()=>(setinstitucional(false))} onMouseOver={()=>(setinstitucional(true))} 
                  className="flex-initial  w-28 text-white font-poppins cursor-pointer mt-5">Institucional
                   { institucional?
                      <div className='border shadow-lg mt-2 absolute border-white z-50 bg-green-800  w-48'>
                        <ul>
                             <li className="cursor-pointer px-4 py-2">
                             <Link to="/organizacion" >Organización </Link>
                             </li>
                             <li className="cursor-pointer px-4 py-2">
                                <Link to="/historia" >Historia</Link>
                             </li>
                             <li className="cursor-pointer px-4 py-2">
                              <Link to="/normativos" >Documentos normativos</Link>
                             </li>
                             <li className="cursor-pointer px-4 py-2">
                              <Link to="/colegiaturas" >Colegiaturas </Link>
                             </li>
                        </ul>
                      </div>:''
                   }
              </div>
              <div onMouseLeave={()=>(setColegiados(false))} onMouseOver={()=>(setColegiados(true))} 
                  className="flex-initial w-28 text-white font-poppins cursor-pointer mt-5">Colegiados
                   { colegiados?
                      <div className='border shadow-lg mt-2 absolute border-white z-50 bg-green-800  w-48'>
                        <ul>
                            <Link to="/buscar-colegiados" className="cursor-pointer px-4 py-2">Buscar Colegiados</Link>
                            <li className="cursor-pointer px-4 py-2">Sistema de cuotas</li>
                        </ul>
                      </div>:''
                   }
              </div>
              <div onMouseLeave={()=>(setServicios(false))} onMouseOver={()=>(setServicios(true))} 
                  className="flex-initial w-24 text-white font-poppins  cursor-pointer  mt-5">
                  Servicios
                    { servicios?
                        <div className='border shadow-lg mt-2 absolute border-white z-50 bg-green-800  w-48'>
                          <ul>
                            <li className="cursor-pointer px-4 py-2">Biblioteca </li>
                            <li className="cursor-pointer px-4 py-2"> Convenios  </li>
                          </ul>
                        </div>:''
                    }
                </div>
              <div onMouseLeave={()=>(setNovedades(false))} onMouseOver={()=>(setNovedades(true))} 
                   className="flex-initial w-28 text-white font-poppins mt-5">Novedades
                      { novedades?
                          <div className='border shadow-lg mt-2 absolute border-white z-50 bg-green-800  w-60'>
                            <ul>
                                <li className="cursor-pointer px-4 py-2">
                                    <Link to="/bolsa-trabajo" >Bolsa de trabajo  </Link>
                                  
                                    </li>
                                <li className="cursor-pointer px-4 py-2 w-full">   
                                    <Link to="/cumpleanos" >Cumpleaños </Link>
                                </li>
                            </ul>
                          </div>:''
                      }
                </div>
              <Link to="/contacto" className="flex-initial relative w-28 text-white font-poppins mt-5 cursor-pointer">Contacto</Link>
              <Link to="/colegiaturas" className="flex-initial relative cursor-pointer  text-white font-poppins mt-3 rounded-lg border pl-4 pr-4 pb-2 pt-2 border-white">Colegiarme</Link>
            </div>
        </div>
      </div>
    </>
  )
}

export default Navbar