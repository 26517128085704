import React from 'react';


const Colegiaturas = () => {
    return (
    <>
        <div className='bg-neutral-100  px-2 py-2 '>
            <div className='mx-24 mt-12'>
                <h1 className='text-4xl mb-6 font-bold font-poppins text-green-800'>Colegiaturas  </h1>
                <p className='text-green-800 w-2/3'>
                En este segmento, el interesado podrá registrar los datos solicitados a fin de incorporarse a la Orden del CORLAD Pasco.
                </p>
            </div>
            <div className='mx-32 mt-12 bg-gray-100 border border-green-800 px-4 py-4 rounded-lg '>
                <h4 className='text-green-800 font-bold'>Requisitos para la colegiatura</h4>
                <div className='mx-10'>
                    <ul>
                        <li className=' text-green-800 mt-4 font-semibold '>
                           - Constancia de inscripción de Título Profesional de la Superintendencia Nacional de Educación Superior (SUNEDU) Ingresar a la página de la SUNEDU ingresar donde indica VERIFICA SI ESTÁS INSCRITO EN EL REGISTRO NACIONAL DE GRADOS Y TÍTULOS e ingresa tu DNI o APELLIDOS Y NOMBRES
                        </li>
                        <li className=' text-green-800 mt-2 font-semibold '>
                        - Dos copia a color del Grado Académico de Bachiller por ambos lados (tamaño A4)
                        </li>
                       <li className=' text-green-800 mt-2 font-semibold  '>
                        - Dos copia a color del Título Profesional por ambos lados (tamaño A4)
                        </li>
                        <li className=' text-green-800 mt-2 font-semibold '>
                        - Dos copia a color del DNI Vigente
                        </li>
                       <li className=' text-green-800 mt-2 font-semibold '>
                        - Seis fotografías tamaño pasaporte con ropa formal y fondo blanco
                        </li>
                   
                       <li className=' text-green-800 mt-2 font-semibold '>
                        - Un (01) file sin fastener
                        </li>
                    </ul>
                </div>
       
            </div>
            <br />
        <br />
        <br />
        <br />
        </div>
       
    </> 
    );
}
 
export default Colegiaturas;