import React from 'react';
import { documentosGenerales } from '../../mock'

const Normativos = () => {
    return (
    <>
        <div className='bg-neutral-100 px-2 py-2'>
            <div className='mx-24 mt-12'>
                <h1 className='text-4xl mb-6 font-bold font-poppins text-green-800'> Documentos Normativos </h1>
                <p className='text-green-800'>
                A partir de un buen funcionamiento y desarrollo institucional debe de partir de una buena base o cimientos que los sostengan, a continuación desarrollamos nuestras normas y estatutos de nuestro colegio profesional.
                </p>
            </div>
            <div className='mx-36 mt-24'>
                <h1 className='text-4xl mb-6 font-bold font-poppins text-green-800'> Normas Generales   </h1>
                <div className='grid grid-cols-5 gap-5'>
                    {
                        documentosGenerales.map((item,index)=>(
                            <div key={index} className='w-full mr-7'>
                                <p className='text-center'>
                                    <span class="material-icons-outlined  text-9xl  text-green-800">
                                        file_open
                                    </span>
                                </p>
                                <p className='text-green-800 font-poppins font-normal mt-12 h-28'>
                                    {
                                        item.nombre
                                    }
                                </p>
                                <a  href={item.link}
                                    target={'_blank'}
                                    className='block rounded-md w-full text-green-800 text-center border border-green-800' rel="noreferrer"
                                >
                                    Ver
                                </a>
                            </div>
                        ))
                    }                
                </div>
            </div>

            <div className='mx-36 mt-24'>
                <h1 className='text-4xl mb-6 font-bold font-poppins text-green-800'>Resoluciones CLAD   </h1>
                <div className='grid grid-cols-5 gap-5'>
                    {
                        documentosGenerales.map((item,index)=>(
                            <div key={index} className='w-full mr-7'>
                                <p className='text-center'>
                                    <span class="material-icons-outlined  text-9xl  text-green-800">
                                        file_open
                                    </span>
                                </p>
                                <p className='text-green-800 font-poppins font-normal mt-12 h-28'>
                                    {
                                        item.nombre
                                    }
                                </p>
                                <a  href={item.link}
                                    target={'_blank'}
                                    className='block rounded-md w-full text-green-800 text-center border border-green-800' rel="noreferrer"
                                >
                                    Ver
                                </a>
                            </div>
                        ))
                    }                
                </div>
            </div>


            <div className='mx-36 mt-24'>
                <h1 className='text-4xl mb-6 font-bold font-poppins text-green-800'>Resoluciones CEN y CER  </h1>
                <div className='grid grid-cols-5 gap-5'>
                    {
                        documentosGenerales.map((item,index)=>(
                            <div key={index} className='w-full mr-7'>
                                <p className='text-center'>
                                    <span class="material-icons-outlined  text-9xl  text-green-800">
                                        file_open
                                    </span>
                                </p>
                                <p className='text-green-800 font-poppins font-normal mt-12 h-28'>
                                    {
                                        item.nombre
                                    }
                                </p>
                                <a  href={item.link}
                                    target={'_blank'}
                                    className='block rounded-md w-full text-green-800 text-center border border-green-800' rel="noreferrer"
                                >
                                    Ver
                                </a>
                            </div>
                        ))
                    }                
                </div>
            </div>
            <br />
            <br />
            <br />
            <br />
        </div>
    </> 
    );
}
 
export default Normativos;